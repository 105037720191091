<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("colleges.colleges") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalSchool.show()">{{
              $t("app.new")
            }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col sm="12" md="11" class="ml-auto mb-2">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    id="app-status-filter"
                    v-model="filter.pendingAppStatusPublishing"
                    name="app-status-filter"
                    class="w-100 mx-5"
                    :value="1"
                    @input="getData()"
                    :unchecked-value="0"
                  >
                    {{ $t("colleges.app_status_pending") }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="have-advertising-filter"
                    v-model="filter.have_advertising"
                    name="have_advertising"
                    class="w-75 mx-5"
                    :value="1"
                    @input="getData()"
                    :unchecked-value="0"
                  >
                    {{ $t("colleges.have-advertising") }}
                  </b-form-checkbox>
                  <b-form-input
                    v-model="filter.search"
                    size="sm"
                    type="search"
                    :placeholder="$t('app.search')"
                    class="w-100"
                  ></b-form-input>
                  <v-select
                    v-model="filter.commune"
                    :placeholder="$t('colleges.commune')"
                    :options="communes"
                    :reduce="(commune) => commune.id"
                    label="name"
                    @input="getData()"
                    class="w-100 px-2"
                  >
                    <template #no-options> Sin Resultados... </template>
                  </v-select>
                </div>
              </b-col>

              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="colleges.data"
                  :fields="columns"
                  :per-page="colleges.per_page"
                  :busy="
                    !Array.isArray(colleges.data) || colleges.data.length === 0
                      ? true
                      : false || collegeLoading == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="collegeLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> {{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(commune)="data">
                    {{ data.item.commune.name }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-primary rounded"
                      class="mx-1"
                      @click="showCollege(data.item)"
                      size="sm"
                      ><i class="ri-eye-fill ml-1"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-warning mr-1 rounded"
                      size="sm"
                      @click="
                        $router.push({
                          name: 'admin.schools-edit',
                          params: { id: data.item.id },
                        })
                      "
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      @click="deleteItem(data.item.id)"
                      ><i class="ri-delete-bin-line ml-1 rounded"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="colleges.total > 0"
                  class="mt-3 float-right"
                  v-model="filter.page"
                  :total-rows="colleges.total"
                  :per-page="colleges.per_page"
                  @change="getData"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>

                <p class="mt-3">
                  {{ $t("app.registers") }} {{ colleges.to }}
                  {{ $t("app.of") }} {{ colleges.total }}. Total
                  {{ colleges.total }}
                </p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalSchool" @refresh="getData()" />
    <b-modal
      size="lg"
      hide-footer
      id="bv-modal-show-college"
      :title="currentCollege && currentCollege.name"
    >
      <section v-if="currentCollege">
        <b-row>
          <b-col cols="12" md="12" sm="12">
            <h6>{{ $t("colleges.name") }}</h6>
            <p class="mt-1">
              {{ currentCollege.name }}
            </p>
          </b-col>
          <b-col cols="12" md="12" sm="12">
            <h6>ID</h6>
            <p class="mt-1" style="word-wrap: break-word">
              {{ currentCollege.id }}
            </p>
          </b-col>
          <b-col cols="12" md="12" sm="12">
            <h6>{{ $t("colleges.image") }}</h6>
            <b-button
              v-if="currentCollege.image"
              class="mt-2"
              variant="primary"
              :href="currentCollege.image"
              target="_blank"
              >{{ $t("colleges.download-image") }}</b-button
            >
            <p class="mt-1" v-else>{{ $t("colleges.not-image") }}</p>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.debouncedGetAnswer = _.debounce(this.getData, 500);
    this.getData();
    this.fetchCommune();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAnswer();
      },
    },
  },
  data() {
    return {
      currentCollege: null,
      filter: {
        paginate: true,
        per_page: 10,
        search: null,
        commmune: null,
        have_advertising: 0,
        pendingAppStatusPublishing: 0,
        page: 1,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchColleges: "fetchColleges",
      deleteCollege: "deleteCollege",
      fetchCommune: "fetchCommune",
    }),
    getData(page) {
      this.filter.page = page;
      this.fetchColleges({
        haveAdvertising: this.filter.have_advertising ? 1 : undefined,
        ...this.filter,
      });
    },
    showCollege(item) {
      this.currentCollege = item;
      this.$bvModal.show("bv-modal-show-college");
    },
    showModalEdit(item) {
      this.$refs.modalSchool.show(item);
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteCollege(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.filter.page = 1;
    },
  },
  computed: {
    ...mapGetters({
      communes: "communes",
      colleges: "colleges",
      collegeLoading: "collegeLoading",
    }),
    columns() {
      return [
        { label: this.$t("colleges.name"), key: "name", class: "text-left" },
        {
          label: this.$t("colleges.acronym"),
          key: "acronym",
          class: "text-left",
        },
        {
          label: this.$t("colleges.commune"),
          key: "commune",
          class: "text-left",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
