<template>
  <b-form-group class="row justify-content-center align-items-center">
    <div class="profile-img-edit" style="cursor: pointer" :id="idUploadImage">
      <b-img
        :src="
          preview ? preview : require('@/assets/images/logo/placeholder.png')
        "
        class="profile-pic height-150 width-150"
        alt="profile-pic"
      />
      <p class="text-center mt-1">
        {{ labelImage }}
      </p>
      <div class="p-image">
        <div class="position-relative">
          <i class="ri-pencil-line upload-button"></i>
        </div>
      </div>
    </div>
    <avatar-cropper
      :upload-handler="selectedFile"
      @changed="setFileName"
      :trigger="`#${idUploadImage}`"
      :labels="{
        submit: $t('app.accept'),
        cancel: $t('app.cancel'),
      }"
      :cropper-options="cropperOptions"
    />
  </b-form-group>
</template>
<script>
import { core } from "@/config/pluginInit";
import AvatarCropper from "vue-avatar-cropper";
export default {
  props: {
    idUploadImage: {
      type: String,
      required: true,
    },
    preview: {
      default: null,
    },
    previewName: {
      type: String,
      required: true,
    },
    labelImage: {
      type: String,
      required: true,
    },
  },
  components: {
    AvatarCropper,
  },
  data() {
    return {
      fileSelected: null,
      cropperOptions: {
        aspectRatio: 0,
        autoCropArea: 1,
        viewMode: 1,
        movable: true,
        zoomable: true,
      },
    };
  },
  methods: {
    setFileName(file) {
      this.fileSelected = file;
      this.$emit("fileChanged", {
        file,
      });
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.$emit("fileSelected", {
        preview: dataUrl,
        previewName: this.previewName,
        file,
      });
    },
  },
};
</script>

<style></style>
