<template>
  <b-modal
    size="xl"
    id="modal-school"
    hide-footer
    :title="!currentData ? $t('colleges.new') : $t('colleges.edit-college')"
  >
    <FormCollage
      @close="$bvModal.hide('modal-school')"
      @save="addCollege"
      :collegeData="currentData"
    />
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import FormCollage from "@/components/Forms/FormsColleges.vue";
import { mapActions } from "vuex";
export default {
  name: "modalFormCollege",
  components: {
    FormCollage,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      currentData: "",
    };
  },
  methods: {
    ...mapActions({
      storeColleges: "storeColleges",
    }),
    async show(item) {
      this.currentData = item;
      this.$bvModal.show("modal-school");
    },
    async addCollege(data) {
      const resp = await this.storeColleges(data);
      this.$bvModal.hide("modal-school");
      this.$emit("refresh");
      if (resp.status === 201 || resp.status === 200) {
        core.showSnackbar(
          "success",
          !data.id ? this.$t("app.success-create") : this.$t("app.success-edit")
        );
      }
    },
  },
};
</script>
