<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div class="new-user-info">
        <b-row align-h="between">
          <b-col md="12" :lg="form.id ? 3 : 12">
            <upload-image
              idUploadImage="pick-image"
              previewName="preview"
              :preview="preview"
              :labelImage="$t('colleges.image')"
              @fileChanged="setPreviewFile"
              @fileSelected="($event) => updateFile($event, 'image')"
            ></upload-image>
            <upload-image
              v-if="form.id"
              idUploadImage="pick-image-splash"
              previewName="previewImageSplash"
              :preview="previewImageSplash"
              :labelImage="$t('colleges.image-splash')"
              @fileChanged="setPreviewFile"
              @fileSelected="($event) => updateFile($event, 'image_splash')"
            ></upload-image>
            <upload-image
              v-if="form.id"
              idUploadImage="pick-image-menu"
              previewName="previewImageMenu"
              :preview="previewImageMenu"
              :labelImage="$t('colleges.image-menu')"
              @fileChanged="setPreviewFile"
              @fileSelected="($event) => updateFile($event, 'image_menu')"
            ></upload-image>
          </b-col>
          <b-col md="12" :lg="form.id ? 9 : 12">
            <b-row class="justify-content-md-start">
              <!-- COLLEGE NAME -->
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.name')"
                label-for="name"
              >
                <ValidationProvider
                  :name="$t('colleges.name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    :placeholder="$t('colleges.name')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <!-- ACRONYM -->
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.acronym')"
                label-for="name"
              >
                <ValidationProvider
                  :name="$t('colleges.acronym')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="form.acronym"
                    type="text"
                    :placeholder="$t('colleges.acronym')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <!-- COLLEGE SHORT_NAME -->
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.short_name')"
                label-for="short_name"
              >
                <ValidationProvider
                  :name="$t('colleges.short_name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="form.short_name"
                    @input="formatSlugByText(form.short_name)"
                    type="text"
                    :placeholder="$t('colleges.short_name')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <!-- COLLEGE SLUG -->
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.slug')"
                label-for="slug"
              >
                <ValidationProvider
                  :name="$t('colleges.slug')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="form.slug"
                    @input="formatSlugByText(form.slug)"
                    type="text"
                    :placeholder="$t('colleges.slug')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <!-- EMAIL -->
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.email')"
                label-for="email"
              >
                <ValidationProvider
                  :name="$t('colleges.email')"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    v-model="form.email"
                    type="text"
                    :placeholder="$t('colleges.email')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.commune')"
                label-for="comune"
              >
                <ValidationProvider
                  :name="$t('colleges.commune')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="form.commune"
                    :placeholder="$t('colleges.commune')"
                    :options="communes"
                    label="name"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                    <template #no-options="{}"> Sin Resultados... </template>
                  </v-select>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-12 col-lg-12"
                :label="$t('colleges.address')"
                label-for="address"
              >
                <ValidationProvider
                  :name="$t('colleges.address')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    v-model="form.address"
                    type="text"
                    rows="5"
                    :placeholder="$t('colleges.address')"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  >
                  </b-form-textarea>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                v-if="userAuth && userAuth.super_admin_role"
                class="col-md-12 col-lg-6"
                :label="$t('colleges.app_status_publishing')"
                label-for="comune"
              >
                <v-select
                  v-model="form.app_status_publishing"
                  :placeholder="$t('colleges.app_status_publishing')"
                  :options="OPTIONS_APP_STATUS_PUBLISHING"
                  label="name"
                >
                  <template #no-options="{}"> Sin Resultados... </template>
                </v-select>
              </b-form-group>
              <b-form-group
                class="col-md-12 col-lg-6"
                :label="$t('colleges.phone')"
                label-for="phone"
              >
                <ValidationProvider
                  :name="$t('colleges.phone')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <vue-tel-input
                    :dropdownOptions="{
                      showFlags: true,
                      width: '200px',
                      tabindex: 1,
                      showDialCodeInSelection: false,
                    }"
                    mode="international"
                    :inputOptions="{
                      placeholder: $t('colleges.phone'),
                      maxlength: 15,
                      showDialCode: false,
                    }"
                    :defaultCountry="56"
                    v-model="form.phone"
                  ></vue-tel-input>
                  <div v-if="errors[0]">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                class="col-md-12 col-lg-6 mt-3"
                label-for="have_advertising"
              >
                <b-form-checkbox
                  id="have-advertising"
                  v-model="form.have_advertising"
                  name="have_advertising"
                  class="mt-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("colleges.have-advertising") }}
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                class="col-md-12 col-lg-6 mt-3"
                label-for="show_onboarding"
              >
                <b-form-checkbox
                  id="show_onboarding"
                  v-model="form.show_onboarding"
                  name="show_onboarding"
                  class="mt-1"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("colleges.show-onboarding") }}
                </b-form-checkbox>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <div>
          <b-button
            class="float-right"
            :disabled="collegeLoading"
            variant="success"
            type="submit"
          >
            <span v-if="collegeLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else> {{ $t("app.save") }} </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            variant="outline-danger"
            @click="$emit('close')"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import UploadImage from "../Cropper/UploadImage.vue";
import { formatStringSlug } from "@/Utils/common";
import { OPTIONS_APP_STATUS_PUBLISHING } from "@/constants/college";

export default {
  components: { UploadImage },
  props: {
    collegeData: {
      type: Object,
    },
  },
  mounted() {
    core.index();
    if (this.collegeData) {
      let app_status_publishing = this.OPTIONS_APP_STATUS_PUBLISHING.find(
        (status) => status.id === this.collegeData.app_status_publishing
      );
      this.form = {
        ...this.collegeData,
        app_status_publishing,
        have_advertising: this.collegeData.have_advertising ? 1 : 0,
        show_onboarding: this.collegeData.show_onboarding ? 1 : 0,
      };
      this.preview = this.collegeData.image;
      this.previewImageMenu = this.collegeData.image_menu;
      this.previewImageSplash = this.collegeData.image_splash;
    }
  },
  data() {
    return {
      OPTIONS_APP_STATUS_PUBLISHING,
      form: {
        id: null,
        name: null,
        short_name: null,
        slug: null,
        commune: null,
        address: null,
        phone: null,
        email: null,
        acronym: null,
        have_advertising: 1,
        app_status_publishing: null,
        show_onboarding: 0,
      },
      fileSelected: null,
      preview: null,
      previewImageMenu: null,
      previewImageSplash: null,
      image: null,
    };
  },
  methods: {
    ...mapActions({
      fetchCommune: "fetchCommune",
      addImageCollege: "addImageCollege",
    }),
    formatSlugByText(text) {
      this.form.slug = formatStringSlug(text);
    },
    async setPreviewFile({ file }) {
      this.fileSelected = file;
      this.image = file;
    },
    async updateFile({ preview, previewName, file }, name_file) {
      this[previewName] = preview;
      this.image = file;
      if (this.form.id) {
        const response = await this.addImageCollege({
          name_image: name_file,
          image: this.image,
          id: this.form.id,
        });
        if (response.status === 200) {
          core.showSnackbar("success", this.$t("app.success-image"));
        }
      }
    },
    async onSubmit() {
      const payload = {
        ...this.form,
        commune_id: this.form.commune?.id || null,
        app_status_publishing: this.form.app_status_publishing?.id || null,
        image: this.image,
      };
      this.$emit("save", payload);
    },
  },
  computed: {
    ...mapGetters({
      userAuth: "user",
      communes: "communes",
      collegeLoading: "collegeLoading",
    }),
  },
};
</script>
<style>
.vue-tel-input {
  border-radius: 7px !important;
  border: 1px solid rgb(211, 211, 211);
  padding: 4px;
}
.vs__dropdown-toggle {
  border-radius: 7px !important;
  padding: 8px 8px 8px 4px !important;
}
</style>
